import AccountTab from './AccountTab';

const SettingsForm = () => {
    return (
        <div className="relative max-w-7xl mx-auto">
            <div className="px-2">
                {/* Setting Tabs */}
                {/* Should have map of tabs and conditional render on click */}
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8">
                        <div className='border-purple-500 text-purple-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-md'>Account</div>
                    </nav>
                </div>
                <div className='pt-8'>
                    <AccountTab />
                </div>
            </div>
        </div>
    )
}

export default SettingsForm;