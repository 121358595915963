import SettingsForm from '../components/settings/SettingsForm';
import SideMenu from '../components/dashboard/SideMenu';
import DashboardHeader from '../components/dashboard/DashboardHeader';

const Settings = () => {
    return (
        <div className="h-screen flex bg-gray-50">
            <SideMenu />
            <div className="flex-1">
                <DashboardHeader />
                <main className="pb-8 z-0">
                    <div className="mt-4">
                        <SettingsForm />
                    </div>
                </main>
            </div>
        </div>
    );
}

export default Settings;