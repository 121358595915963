import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import {useAuthState, useAuthDispatch} from '../../contexts/auth/AuthContext';
import { logout } from '../../contexts/auth/AuthAction';
import { getChevronDownIcon, getProfileIcon } from '../../helper/SvgHelper';


const ProfileDropdown = () => {

    const history = useHistory();
    const { user } = useAuthState();
    const dispatch = useAuthDispatch();

    const ref = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleDropdown = () => { setShowDropdown(!showDropdown);}

    const handleLogout = async () => { 
        try {
            setShowDropdown(false);
            await logout(dispatch);
        }
        catch(err) {
            console.log(err);
        }
        finally {
            history.push('/login');
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowDropdown(false);
              }
        }

        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);


    return (
        <div className="ml-3 relative">
            <div ref={ ref }>
                <div>
                    <button onClick={ handleDropdown }  className="max-w-xs bg-white flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-2 rounded-md hover:bg-gray-50">
                        { getProfileIcon() }
                        <span className="ml-3 text-gray-700 text-sm font-medium block">{ user ? user.name : null }</span>
                        { getChevronDownIcon() }
                    </button>
                </div>

                {showDropdown && <div className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                    <Link to='/dashboard/profile' onClick={ () => { setShowDropdown(false); } }>
                        <span className="block text-center px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-blue-100">Profile</span>
                    </Link>
                    <button onClick={ handleLogout } className="block w-full px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-blue-100">Logout</button>
                </div>}
            </div>
        </div>
    );
}

export default ProfileDropdown;