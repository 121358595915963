import React, { useState, useEffect, useRef, useMemo} from 'react';
import {getFilterIcon, getSortingIcon, getDoubleArrowLeftIcon, getDoubleArrowRightIcon, getSingleArrowLeftIcon, getSingleArrowRightIcon, getRefreshIcon, getSpinnerIcon} from '../../helper/SvgHelper';
import useWindowDimensions from '../../helper/WindowDimensionHelper';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from "react-table";
import { matchSorter } from 'match-sorter'


let resultFilter = null;

function ResultColumnFilter({column: { filterValue, setFilter, preFilteredRows, id},}) {
    const resultOptionRef = useRef(null);
    const [showResultFilterOption, setShowResultFilterOption] = useState(false);

    const customOptions = ['Complete', 'Incomplete'];
    
    const setSelectedFilterOptions = (status) => {
        resultFilter = status;
        setFilter(resultFilter);
    }

    const clearFilter = () => {
        resultFilter = null; 
        setFilter(undefined);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(resultOptionRef.current && !resultOptionRef.current.contains(event.target)) {
                setShowResultFilterOption(false);
            }
        }
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    
    return (
        <div ref={ resultOptionRef } className="relative inline-block text-left ml-1">
            <div className="cursor-pointer">
                <span onClick={ (e) => {setShowResultFilterOption(!showResultFilterOption); e.stopPropagation(); }}>
                    {getFilterIcon(resultFilter)}
                </span>
            </div>
            {showResultFilterOption && preFilteredRows.length > 0 &&
            <div onClick={ (e)=>{ e.stopPropagation(); }} className="origin-top-right absolute right-0 mt-2 p-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                {customOptions.map((option, i) => (
                    <div className="flex items-center h-5 px-3 my-2" key={i}>
                        <input checked={ resultFilter === option } type="radio" onChange={ () => setSelectedFilterOptions(option) } className="form-checkbox h-4 w-4 border-gray-300" /><span className="ml-3 text-gray-700" >{option}</span>
                    </div>
                ))}
                <div className="flex items-end justify-between h-7 px-2 mt-2 border-t border-gray-300">
                    <button type="button" className="justify-self-end px-1 py-0.5 border border-transparent text-xs rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none" onClick={ ()=> setShowResultFilterOption(false) }>Hide</button>
                    <button type="button" className="justify-self-end px-1 py-0.5 border border-transparent text-xs rounded-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none" onClick={ ()=> clearFilter() }>Clear</button>
                </div>
            </div>
            }
        </div>
    )
}

function filterResultOptions(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id];
        
        return  filterValue === 'Complete' ? !!rowValue === true : !!rowValue === false;
    });
}

function DefaultColumnFilter({column: { filterValue, preFilteredRows, setFilter },}) {
    //const count = preFilteredRows.length
    return (<></>)
}

const SortingIcon = ({columnName, currentColumnName, sortAsc}) => {
    return (
        <>
            {getSortingIcon(columnName, currentColumnName, sortAsc)}
        </>
    )
};

function SortByResult(a, b) {
    if (!isNaN(a)) {
        if (!isNaN(b)) {
            return a < b ? 1 : -1;
        }
        return 1;
    }

    if (typeof a === 'string' && a) {
        if (!isNaN(b)) {
            return -1;
        }
        if (typeof b === 'string' && b) {
            return a.length > b.length ? -1 : 1;
        }
        return -1;
    }
    if(!a) {
        if (!isNaN(b)) {
            return -1;
        }
        if (typeof b === 'string' && b) {
            return 1;
        }
        return 0;
    }
}


function GlobalFilter({preGlobalFilteredRows, globalFilter, setGlobalFilter, getRegistrationList, setRegistrationList}) {

    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce(value => { setGlobalFilter(value || undefined) }, 200);
    
    return (
        <div className="ml-4">
            <input 
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }} 
                type="search"
                placeholder="Search"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm  focus:outline-none text-sm"
            />
        </div>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
} 
fuzzyTextFilterFn.autoRemove = val => !val;

// Table UI control Start
const DonwnLoadButton = ({setDownloadReportCatalog, setShowResult}) => {
    return (
        <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <button onClick={() =>{setShowResult(false); setDownloadReportCatalog(true); }} type="button" className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Create Report
            </button>      
        </div>
    );
}

function ReactTablePagination ({page,canPreviousPage,canNextPage,pageOptions,pageCount,gotoPage,nextPage,previousPage,pageIndex,pageSize,setPageSize, rows, setShowResult, setSelectedRow, setDownloadReportCatalog}) {
    const [dynamicPageOffsetNegTwo, setDynamicPageOffsetNegTwo] = useState(2);
    const [dynamicPageOffsetNegOne, setDynamicPageOffsetNegOne] = useState(3);
    const [dynamicPageOffsetNil, setDynamicPageOffsetNil] = useState(4);
    const [dynamicPageOffsetPosOne, setDynamicPageOffsetPosOne] = useState(5);
    const [dynamicPageOffsetPosTwo, setDynamicPageOffsetPosTwo] = useState(6);

    const DoubleArrowLeft = ({gotoPage, pageIndex}) => {
        let pageNum = pageIndex-5 >= 0 ? pageIndex - 5 : 0;
        return (
            <div>
                <button onClick={() => gotoPage(pageNum)} disabled={pageIndex < 4} className="-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-xl leading-5 font-bold text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Previous-five">
                    {getDoubleArrowLeftIcon()}
                </button>
            </div>
        )
    }

    const DoubleArrowRight = ({gotoPage, pageIndex, pageCount}) => {
        let pageNum = pageIndex + 5 <= pageCount - 1 ? pageIndex + 5 : pageCount - 1;
        return (
            <div>
                <button onClick={() => gotoPage(pageNum)} disabled={pageIndex + 4 > pageCount} className="-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-xl leading-5 font-bold text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Next-five">
                    {getDoubleArrowRightIcon()}
                </button>
            </div>
        )
    }

    const SingleArrowLeft = ({previousPage, canPreviousPage}) => {
        return (
            <div>
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-gray-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Previous">
                    {getSingleArrowLeftIcon()}
                </button>
            </div>
        )
    }
    const SingleArrowRight = ({nextPage, canNextPage}) => {
        return (
            <div>
                <button onClick={() => nextPage()} disabled={!canNextPage} className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-gray-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Next">
                    {getSingleArrowRightIcon()}
                </button>
            </div>
        )
    }

    useEffect(() => {
        const setPageNumbers = () => {
            if(pageCount <= 7) {
                setDynamicPageOffsetNegTwo(2);
                setDynamicPageOffsetNegOne(3);
                setDynamicPageOffsetNil(4);
                setDynamicPageOffsetPosOne(5);
                setDynamicPageOffsetPosTwo(6);
            } else {
                if(pageIndex + 3 >= pageCount) {
                    setDynamicPageOffsetNegTwo(pageCount - 5);
                    setDynamicPageOffsetNegOne(pageCount - 4);
                    setDynamicPageOffsetNil(pageCount - 3);
                    setDynamicPageOffsetPosOne(pageCount - 2);
                    setDynamicPageOffsetPosTwo(pageCount - 1);
                } 
                else if (pageIndex >= 4) {
                    setDynamicPageOffsetNegTwo(pageIndex -1);
                    setDynamicPageOffsetNegOne(pageIndex);
                    setDynamicPageOffsetNil(pageIndex + 1);
                    setDynamicPageOffsetPosOne(pageIndex + 2);
                    setDynamicPageOffsetPosTwo(pageIndex + 3);
                }
                else {
                    setDynamicPageOffsetNegTwo(2);
                    setDynamicPageOffsetNegOne(3);
                    setDynamicPageOffsetNil(4);
                    setDynamicPageOffsetPosOne(5);
                    setDynamicPageOffsetPosTwo(6);
                }
            }
        }
        setPageNumbers();
    }, [pageIndex]);

    return (
        <nav className="px-4 py-3 flex items-center justify-between border-t border-gray-200 bg-gray-100 rounded-br-lg rounded-bl-lg">
            <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <button onClick={()=>{ setShowResult(false); setSelectedRow(false); }} type="button" className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    Close
                </button>      
            </div>

            <DonwnLoadButton setDownloadReportCatalog={setDownloadReportCatalog} setShowResult={setShowResult} />
            
            <div className="hidden sm:block">
                <p className="text-gray-500 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium">
                    Showing {rows.length > 0 ? 1 : 0 + pageIndex * pageSize } to { (pageIndex + 1) * pageSize < rows.length ?  (pageIndex + 1) * pageSize : rows.length } of { rows.length } Results
                </p>
            </div>

            <div className="flex-1 flex justify-between sm:justify-end">
                <SingleArrowLeft previousPage={previousPage} canPreviousPage={canPreviousPage}/>
                <div>
                    <button onClick={() => gotoPage(0)} value={1} className={`-ml-px ${pageIndex===0 ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:z-10 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:underline`}>
                        1
                    </button>
                </div>
                { (pageIndex + 1) >= 5 && pageCount > 7 && <DoubleArrowLeft gotoPage={gotoPage} pageIndex={pageIndex}/> }
                <div className={`${pageCount > 2 ? "" : "hidden"}`}>
                    <button onClick={() => gotoPage(dynamicPageOffsetNegTwo-1)} value={dynamicPageOffsetNegTwo} className={`-ml-px ${(pageIndex + 1)===dynamicPageOffsetNegTwo ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out hover:underline`}>
                        { dynamicPageOffsetNegTwo }
                    </button>
                </div>
                <div className={`${pageCount > 3 ? "" : "hidden"}`}>
                    <button onClick={() => gotoPage(dynamicPageOffsetNegOne-1)} value={dynamicPageOffsetNegOne} className={`-ml-px ${(pageIndex + 1)===dynamicPageOffsetNegOne ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out hover:underline`}>
                        { dynamicPageOffsetNegOne }
                    </button>
                </div>
                <div className={`${pageCount > 4 ? "" : "hidden"}`}>
                    <button onClick={() => gotoPage(dynamicPageOffsetNil-1)} value={dynamicPageOffsetNil} className={`-ml-px ${(pageIndex + 1)===dynamicPageOffsetNil ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out hover:underline`}>
                        { dynamicPageOffsetNil }
                    </button>
                </div>
                <div className={`${pageCount > 5 ? "" : "hidden"}`}>
                    <button onClick={() => gotoPage(dynamicPageOffsetPosOne-1)} value={dynamicPageOffsetPosOne} className={`-ml-px ${(pageIndex + 1)===dynamicPageOffsetPosOne ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out hover:underline`}>
                        { dynamicPageOffsetPosOne }
                    </button>
                </div>
                <div className={`${pageCount > 6 ? "" : "hidden"}`}>
                    <button onClick={() => gotoPage(dynamicPageOffsetPosTwo-1)} value={dynamicPageOffsetPosTwo} className={`-ml-px ${(pageIndex + 1)===dynamicPageOffsetPosTwo ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out hover:underline`}>
                        { dynamicPageOffsetPosTwo }
                    </button>
                </div>
                { (pageCount - (pageIndex + 1) > 3) && pageCount > 7 && <DoubleArrowRight gotoPage={gotoPage} pageIndex={pageIndex} pageCount={pageCount} /> }
                <div className={`${pageCount > 1 ? "" : "hidden"}`}>
                    <button onClick={() => gotoPage(pageCount-1)} value={pageCount} className={`-ml-px ${(pageIndex + 1)===pageCount ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:z-10 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:underline`}>
                        { pageCount }
                    </button>
                </div>
                <SingleArrowRight nextPage={nextPage} canNextPage={canNextPage}/>
            </div>
        </nav>
    )
}



const RefreshIcon = ({getRegResultList, selectedRegTID, setSortColumnName, setSortAsc}) => {

    const [loading, setLoading] = useState(false);

    const refreshRegistrationList = async () => {
        setLoading(true);
        resultFilter = null;
        setSortColumnName(null);
        setSortAsc(null);
        await getRegResultList(selectedRegTID);
        setLoading(false);
    }

    return (
        <div className="h-6 w-6">
            <button className="ml-6 py-1 text-center focus:outline-none" onClick={()=>refreshRegistrationList()}>
                {loading ? getSpinnerIcon() : getRefreshIcon("#6b7280")}
            </button>
        </div>
    );
}

const ReactTable = ({ columns, data, getRegResultList, setShowResult, selectedRow, setSelectedRow, setDownloadReportCatalog}) => {
    // const filterTypes = useMemo(() => ({
    //     fuzzyText: fuzzyTextFilterFn,

    //     text: (rows, id, filterValue) => {
    //         return rows.filter(row => {
    //             const rowValue = row.values[id]
    //             return rowValue !== undefined
    //                     ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
    //                     : true
    //         })
    //     },
    // }), []);

    const [sortColumnName, setSortColumnName] = useState(null);
    const [sortAsc, setSortAsc] = useState(null);
    
    const { height, width } = useWindowDimensions();
    let trHeight = 53;
    let nonTrHeight = 329;

    const defaultColumn = useMemo(
        () => ({
          Filter: DefaultColumnFilter,
        }),[]
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        loading,
        setFilter,

        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        },
        defaultColumn,
        //filterTypes
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const changeSortingColumn = (column) => {
        
        if(!column.canSort) { return; }

        if(column.Header === sortColumnName) {
            if(sortAsc === true) { 
                setSortAsc(false);
                return;
            }
            if(sortAsc === false) { 
                setSortAsc(null);
                return;
            }
            if(sortAsc === null) { 
                setSortAsc(true);
                return;
            }
        }
        
        setSortColumnName(column.Header);
        setSortAsc(true);
    }

    useEffect(() => {
        Math.floor((height - nonTrHeight)/trHeight) > 1 ? setPageSize(Math.floor((height - nonTrHeight)/trHeight)) : setPageSize(1);
    },[height]);

    return (
        <>
            <div className="-mb-2 flex p-4 items-center justify-center bg-gray-100 rounded-tr-lg rounded-tl-lg">
                <div className="px-4 border-r">Reg No.: { selectedRow.URegRef } </div>
                <div className="px-4 border-r">Batch No.: { selectedRow.RegKeyFld } </div>
                <div className="px-4 border-r max-w-511 truncate">Description: { selectedRow.JobDescrip } </div>
                <div className="px-4 border-r">No. of Samples: { selectedRow.NoSamp } </div>
                <div className="px-4 border-r">Status: { selectedRow.StatCd } </div>
                <div className="px-4">Due Date: { selectedRow.DueDate } </div>
                <GlobalFilter 
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                <RefreshIcon getRegResultList={getRegResultList} selectedRegTID={selectedRow.RegTID}  setSortColumnName={setSortColumnName} setSortAsc={setSortAsc}/>
            </div>
            <table className="w-full divide-y divide-gray-200" {...getTableProps()}>
                <thead className="bg-gray-100">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th 
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" 
                                    key={column.Header} 
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                >
                                    <div 
                                        className="flex items-center justify-center"
                                        onClick={() => { changeSortingColumn(column); }}
                                    >
                                        <div className="inline-block mr-1.5">{column.render('Header')}</div>
                                        {!column.disableSortBy && <SortingIcon columnName={column.Header} currentColumnName={sortColumnName} sortAsc={sortAsc} />}
                                        {column.canFilter ? column.render('Filter') : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className="bg-white divide-y divide-gray-200" {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr className="hover:bg-gray-50" {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                            <td className="px-6 py-4 max-w-xs" key={ i } {...cell.getCellProps()}>
                                                <div className="text-sm text-center text-gray-900">
                                                    {cell.render('Cell')}
                                                </div>
                                            </td>
                                            )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <ReactTablePagination 
                page={page} 
                canPreviousPage={canPreviousPage} 
                canNextPage={canNextPage} 
                pageOptions={pageOptions} 
                pageCount={pageCount}
                gotoPage={gotoPage}
                nextPage={nextPage}
                previousPage={previousPage}
                setPageSize={setPageSize}
                pageIndex={state.pageIndex}
                pageSize={state.pageSize}
                rows = {rows}
                setShowResult = {setShowResult}
                setSelectedRow = {setSelectedRow}
                setDownloadReportCatalog={setDownloadReportCatalog}
            />
        </>
    );
};

const RegistrationResultTable = ({regResultList, getRegResultList, setShowResult, isIANZ, selectedRow, setSelectedRow, setDownloadReportCatalog}) => {
    resultFilter = null;
    const columnCNAS = [
        {
            Header: 'Test Item',
            accessor: 'RptLabel'
        },
        {
            Header: 'Method',
            accessor: 'MDescrip',
            disableSortBy: true
        },
        {
            Header: 'Unit',
            accessor: 'Units',
            disableSortBy: true
        },
        {
            Header: 'LOQ',
            accessor: 'LMin',
            disableSortBy: true
        },
        {
            Header: 'Result',
            accessor: 'TestResult',
            Filter: ResultColumnFilter,
            filter: filterResultOptions,
            sortMethod: (a, b) => {
                SortByResult(a, b)
            }
        }
    ];

    const columnIANZ = [
        {
            Header: 'Labratory ID',
            accessor: 'URegRef'
        },
        {
            Header: 'Sample ID',
            accessor: 'SmKFld',
            disableSortBy: true
        },
        {
            Header: 'Test Item',
            accessor: 'RptLabel',
            disableSortBy: true
        },
        {
            Header: 'Method',
            accessor: 'MDescrip',
            disableSortBy: true
        },
        {
            Header: 'Unit',
            accessor: 'Units',
            disableSortBy: true
        },
        {
            Header: 'LOQ',
            accessor: 'LMin',
            disableSortBy: true
        },
        {
            Header: 'Result',
            accessor: 'TestResult',
            Filter: ResultColumnFilter,
            filter: filterResultOptions,
            sortMethod: (a, b) => {
                SortByResult(a, b)
            }
        }
    ];

    return (
        <>
        {regResultList &&
            <div className="w-screen h-screen fixed left-0 top-0 bg-gray-800 bg-opacity-80 z-20 flex items-center">
                <div className="w-11/12 mt-2 mx-auto">
                    <ReactTable columns={isIANZ ? columnIANZ : columnCNAS} data={regResultList} getRegResultList={getRegResultList} setShowResult={setShowResult} selectedRow={selectedRow} setSelectedRow={setSelectedRow} setDownloadReportCatalog={setDownloadReportCatalog}/>
                </div>
            </div>
        }
        </>
    );
}

export default RegistrationResultTable;



