import {BrowserRouter, Switch} from 'react-router-dom';
import routes from './config/Route';
import  AuthProvider from './contexts/auth/AuthContext';
import ProtectRoute from './components/ProtectRoute';

function App() {

  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="App">
          <div className="content">
            <Switch>
              {routes.map((route)=> (
                <ProtectRoute 
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              ))}
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
