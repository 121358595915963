import { getChevronRightIcon } from '../../helper/SvgHelper'

const HomeInfo = () => {
    return (
        <div className="px-6 col-span-6 text-left items-center">
            <div className="h-3/4 py-20">
                <a href="mailto:it_support@caiqtest.co.nz" className="inline-flex items-center text-white bg-gray-900 rounded-full p-1 pr-2 text-base hover:text-gray-200">
                    <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-indigo-500 rounded-full">Infomation & Inquiry</span>
                    <span className="ml-4 text-sm">Please email us</span>
                    { getChevronRightIcon() }
                </a>

                <h1 className="tracking-tight font-extrabold text-white mt-8 text-6xl">
                    <span className="block">NZ food testing for</span>
                    <span className="text-indigo-400 block mt-2">exporting to China</span>
                </h1>
                
                <p className="text-gray-300 mt-6 text-xl">
                    Opportunity to have goods tested prior to shipment, to a standard accepted by the CIQ.
                    <br/>
                    <br/>&emsp;&bull; Pre-Shipment Chinese border approval
                    <br/>&emsp;&bull; Faster product release for exporters into China
                </p>
            </div>
        </div>
    );
}

export default HomeInfo;