import { useEffect } from 'react';
import { useAuthDispatch } from '../../contexts/auth/AuthContext';
import logout from '../../contexts/auth/AuthAction';
import ProfileDropdown from './ProfileDropdown';
import DashboardHeaderNav from './DashboardHeaderNav';
import { getBellIcon } from '../../helper/SvgHelper';

const DashboardHeader = () =>{

    const dispatch = useAuthDispatch();

    useEffect(() => {
        document.addEventListener('beforeunload', function() { logout(dispatch); });
    });

    return (
        <>
            <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
                <div className="flex-1 px-8 flex justify-between max-w-6xl mx-auto">
                    <DashboardHeaderNav />
                    
                    <div className="ml-6 flex items-center">
                        <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                            { getBellIcon() }
                        </button>
                        
                        <ProfileDropdown />
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardHeader;