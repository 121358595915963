import {getBeakerIcon, getQuestionMarkIcon, getGearIcon, getPencilNoteIcon} from '../../helper/SvgHelper';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuthState } from '../../contexts/auth/AuthContext';


const SideMenu = () => {
    let iconMapper ={}
    iconMapper['Registration'] = getBeakerIcon();
    iconMapper['Settings'] = getGearIcon();
    iconMapper['Sample Submission'] = getPencilNoteIcon();

    const user = useAuthState();

    const [currentTab, setCurrentTab] = useState(null);

    useEffect(() =>{
        const hrefArray = window.location.href.split('/');
        setCurrentTab(hrefArray[hrefArray.length - 1]);
    }, [])

    return (
        <div className="flex flex-shrink-0">
            <div className="flex flex-col w-60">
                <div className="flex flex-col flex-grow bg-gray-800 pt-5 pb-2">
                    <div className="flex items-center flex-shrink-0 pl-6">
                        <img className="h-11 w-auto" src="/report-logo-no-bg-invert.png" alt="CAIQTest Logo" />
                    </div>
                    <nav className="mt-6 flex-1 flex flex-col divide-y divide-gray-600">
                        <div className="px-2 space-y-1">
                            {user && user.user && user.user.privileges.map((tab, i) => (
                                tab !== 'Settings' &&
                                <Link
                                    key={i} 
                                    to={'/dashboard/' + tab.toLowerCase().replace(/\s/g, '')} 
                                    className={`${tab.replace(/\s/g, '').toLowerCase() === currentTab ? 'bg-gray-700' : ''} group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-gray-700`}
                                >
                                    <div className="mr-4">
                                        { iconMapper[tab] }
                                    </div>
                                    {tab}
                                </Link>
                            ))}

                            {/* Extra space start */}
                            <span className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100"></span>
                            <span className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100"></span>
                            <span className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100"></span>
                            <span className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100"></span>
                            <span className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100"></span>
                            <span className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100"></span>
                            <span className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100"></span>
                            <span className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100"></span>
                            {/* Extra space end */}
                        </div>
                        <div className="mt-6 pt-6">
                            <div className="px-2 space-y-1">
                                {user && user.user && user.user.privileges.map((tab, i) => (
                                    tab === 'Settings' &&
                                    <Link
                                        key={i} 
                                        to={'/dashboard/' + tab.toLowerCase()} 
                                        className={`${tab.toLowerCase() === currentTab ? 'bg-gray-700' : ''} group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-gray-700`}
                                    >
                                        { iconMapper[tab] }
                                        {tab}
                                    </Link>
                                ))}

                                <a href ='mailto:it_support@caiqtest.co.nz' className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-200 hover:text-white hover:bg-gray-700">
                                    {getQuestionMarkIcon()}
                                    Help
                                </a>

                                {/* Will come back to this later */}
                                {/* <span className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600">
                                    <!-- Heroicon name: shield-check --> 
                                    {getGuardIcon()}
                                    Privacy
                                </span>*/}
                            </div>
                        </div>
                    </nav>
                    <div className="block text-gray-500 text-xs text-center">
                        &copy; { new Date().getFullYear() } - CAIQTest Pacific Limited
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default SideMenu;