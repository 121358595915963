import SideMenu from '../components/dashboard/SideMenu';
import DashboardHeader from '../components/dashboard/DashboardHeader';

const Dashboard = () => {
    return (
        <div className="h-screen flex overflow-hidden bg-gray-50">
            <SideMenu />
            <div className="flex-1 overflow-auto focus:outline-none">
                <DashboardHeader />
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
                    <div className="mt-8">
                    </div>
                </main>
            </div>
        </div>
    );
}

export default Dashboard;

