import { Page, Text, View, Image, Document } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import { IsoDateToNzDateWithMonthNameStr } from '../../helper/DateHelper';

const CnasChnReportTemplate = ({data}) => {

  Font.register({
    //https://fonts.google.com/specimen/Open+Sans#standard-styles
    // thin	Equals to value 100
    // ultralight	Equals to value 200
    // light	Equals to value 300
    // normal	Equals to value 400 Default
    // medium	Equals to value 500
    // semibold	Equals to value 600
    // bold	Equals to value 700
    // ultrabold	Equals to value 800
    // heavy	Equals to value 900
    // number	Any integer value between 0 and 1000
    family: 'open-sans',
    fonts: [
      {
        src: '/fonts/open-sans/OpenSans-Light.ttf',
        fontWeight: 'light'
      },
      {
        src: '/fonts/open-sans/OpenSans-Regular.ttf',
        fontWeight: 'normal'
      },
      {
        src: '/fonts/open-sans/OpenSans-SemiBold.ttf',
        fontWeight: 'semibold'
      },
      {
        src: '/fonts/open-sans/OpenSans-Bold.ttf',
        fontWeight: 'bold'
      }
    ]
  })

  Font.register({
    family: 'ms-black',
    fonts: [
      {
        src: '/fonts/ms-black/FangSong.ttf',
        fontWeight: 'light'
      },
      {
        src: '/fonts/ms-black/msyhbd.ttf',
        fontWeight: 'bold'
      }
    ]
  })

  const pageStyle = {
      paddingTop: 16,
      paddingHorizontal: 40,
      paddingBottom: 65
  };

  const createReportHeader = () => {
    return (
      <View style={{ flexDirection: "row" }} fixed>
        <View style={{ width: "50%" }}>
          <Image src="/report-logo.png"/>
        </View>

        <View style={{ width: "50%" }}>
          <View style={{ paddingTop: 20, float: 'right', textAlign: 'right', lineHeight: 1.2 }}>
            <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9 }}>CAIQTEST Pacific Ltd.</Text>
            <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9 }}>Ruakura Research Centre</Text>
            <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9 }}>10 Bisley Road</Text>
            <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9 }}>Private Bag 3123, Hamilton 3240</Text>
          </View>
        </View>
      </View>
    );
  };

  const createReportTittle = () => {
    return (
      <View style={{ textAlign: 'center', margin: '20px 0' }} fixed>
          <Text style={{ fontFamily: 'ms-black', fontWeight: 'bold', fontSize: 15 }}>检测报告 <Text style={{ fontFamily: 'open-sans', fontWeight: 'bold', fontSize: 15 }}>Test Report</Text></Text>
      </View>
    );
  };

  const createReportPagination = () => {
    return (
      <>
        <View style={{ flexDirection: "row", marginBottom: 2 }} fixed>
          <View  style={{ width: "22%" }}>
            <Text style={{ paddingLeft: 5, fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10 }}>报告编号(No.):</Text>
          </View>
          <View  style={{ width: "35%" }}>
            <Text style={{ textAlign: "left",paddingLeft: 5, fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportInfo.caiqId}</Text>
          </View>
        </View>

        <Text style={{ fontFamily: 'ms-black', fontWeight: 'light', fontSize: 9, position: 'absolute', right: 45, top: 158 }} render={({ pageNumber, totalPages }) => (
          `页码 ${pageNumber} 共 ${totalPages} 页`
        )} fixed/>
      </>
    );
  };

  const createDescripTable = () => {
    return (
      <View style={{ display: "table", width: "auto"}}  fixed>
        <View style={{ flexDirection: "row"}}>
          <View style={{ width: "22%", borderStyle: "solid", borderWidth: 1 }}>
            <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2  }}>委托单位</Text>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>Client</Text>
          </View>

          <View style={{ width: "78%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 11, margin: 6 }}>{data.reportData[0].ClientName}</Text>
          </View>
        </View>

        <View style={{ flexDirection: "row"}}>
          <View style={{ width: "22%", borderStyle: "solid", borderWidth: 1, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>样品名称</Text>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>Sample Name</Text>
          </View>

          <View style={{ width: "41%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 7 }}>{data.reportData[0].JobDescrip}</Text>
          </View>
          
          <View style={{ width: "22%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>检测类别</Text>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>Test Classification</Text>
          </View>

          <View style={{ width: "15%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>日常委托</Text>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>Commitment</Text>
          </View>
        </View>

        <View style={{ flexDirection: "row"}}>
          <View style={{ width: "22%", borderStyle: "solid", borderWidth: 1, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>样品标识</Text>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>Sample ID</Text>
          </View>

          <View style={{ width: "41%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 7 }}>{data.reportInfo.clientBatch}</Text>
          </View>
          
          <View style={{ width: "22%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>样品编号</Text>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>Sample Reference No.</Text>
          </View>

          <View style={{ width: "15%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 7 }}>{data.reportData[0].URegRef}</Text>
          </View>
        </View>

        <View style={{ flexDirection: "row"}}>
          <View style={{ width: "22%", borderStyle: "solid", borderWidth: 1, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>样品描述</Text>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>Sample Description</Text>
          </View>

          <View style={{ width: "41%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginTop: 1, marginBottom: 1 }}>{data.reportData[0].JobDescrip}{data.reportInfo.weightUnit && ', '}{data.reportInfo.weightUnit}{data.reportInfo.prodDate && ', '}{data.reportInfo.prodDate}{data.reportInfo.prodDate && ' - '}{data.reportInfo.expiryDate}</Text>
          </View>
          
          <View style={{ width: "22%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>报告日期</Text>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>Issued Date</Text>
          </View>

          <View style={{ width: "15%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginTop: 7 }}>{IsoDateToNzDateWithMonthNameStr(new Date())}</Text>
          </View>
        </View>

        <View style={{ flexDirection: "row"}}>
          <View style={{ width: "100%", borderStyle: "solid", borderWidth: 1, borderTopWidth: 0 }}>
            <Text style={{ textAlign: "left", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, margin: '0 5' }}>声明 <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '0 5' }}>Clarification:</Text></Text>
            <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '0 5' }}>1.<Text style={{ textAlign: "left", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, margin: '0 5' }}>本报告中样品名称与标识由客户提供，我中心不承担证实其信息准确性责任。</Text>The sample’s name and ID are &nbsp;&nbsp;&nbsp;&nbsp;provided by the client. CAIQTEST is not responsible for the accuracy of the provided information.</Text>
            <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '0 5' }}>2.<Text style={{ textAlign: "left", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, margin: '0 5' }}>根据客户要求完成检测内容，检测结果只对来样负责。测试日期可根据要求提供。</Text>All tests have been taken &nbsp;&nbsp;&nbsp;&nbsp;according to the client’s request. Results are reported on an ‘’as received” basis and pertain only to the &nbsp;&nbsp;&nbsp;&nbsp;samples submitted. Dates of testing are available on request.</Text>
            <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '0 5' }}>3.<Text style={{ textAlign: "left", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, margin: '0 5' }}>“*”按照委托书进行的分包项目(分包实验室Analytica拥有IANZ及MPI资质)。</Text>“*” sub-contracted test as per &nbsp;&nbsp;&nbsp;&nbsp;agreement with customer (sub-contracted to Analytica Laboratories, IANZ accredited, MPI RLP)</Text>
          </View>
        </View>
      </View>
    );
  };

  const createTestResultTable =() => {
    return (
      <>
        <View style={{ display: "table", width: "auto", marginTop: 10 }} fixed>
          <View style={{ flexDirection: "row"}}>
            <View style={{ width: "36.5%", borderStyle: "solid", borderWidth: 1 }}>
              <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>检测项目</Text>
              <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>Test Item</Text>
            </View>

            <View style={{ width: "29%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0 }}>
              <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>检测方法</Text>
              <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>Method</Text>
            </View>
            
            <View style={{ width: "14%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0 }}>
              <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>单位</Text>
              <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>Unit</Text>
            </View>

            <View style={{ width: "8.5%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0 }}>
              <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>测定低限</Text>
              <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>LOQ</Text>
            </View>

            <View style={{ width: "12%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0 }}>
              <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>检测值</Text>
              <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>Result</Text>
            </View>
          </View>
        </View>

        <View style={{ display: "table", width: "auto" }}>
          {data.reportData.map((result, i) => (
            <View key={i} style={{ flexDirection: "row"}}  wrap={false}>
              <View style={{ width: "36.5%", borderStyle: "solid", borderWidth: 1, borderTopWidth: 0 }}>
                {result.ChnLabel && <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>{result.ChnLabel}</Text> }
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: result.ChnLabel ? 1 : 5 }}>{result.RptLabel}</Text>
              </View>

              <View style={{ width: "29%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                {result.ChnMethod && <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>{result.ChnMethod}</Text> }
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginTop: result.ChnLabel ? result.ChnMethod ? 2 : 7 : 5, marginBottom: result.ChnMethod ? 1 : 0 }}>{result.MDescrip}</Text>
              </View>
              
              <View style={{ width: "14%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginTop: result.ChnLabel ? 7 : 5 }}>{result.Units}</Text>
              </View>

              <View style={{ width: "8.5%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginTop: result.ChnLabel ? 7 : 5 }}>{result.LMin}</Text>
              </View>
              
              <View style={{ width: "12%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                {result.ChnResult && result.ChnResult.length > 8 &&result.ChnResult.match(/.{1,6}/g).map((str, j) => (<Text key={j} style={{ textAlign: "left", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>{str}</Text> ))}
                {result.ChnResult && result.ChnResult.length <= 8 && <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 2 }}>{result.ChnResult}</Text>}
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: result.TestResult ? result.TestResult.length > 10 ? 9 : 10 : 10, marginTop: result.ChnLabel ? result.ChnResult ? 2 : 7 : 5, marginBottom: result.ChnResult ? 1 : 0, marginLeft: 3, marginRight: 3}}>{result.TestResult}</Text>
              </View>
            </View>
          ))}
        </View>
      </>
    )
  }

  const createReportFooter =() => {
    return (
      <View style={{ display: "table", width: "auto", position: "absolute", left: 40, bottom: 25, height:45 }} fixed>
          <View style={{ flexDirection: "row"}}>
            <View style={{ width: "22%", borderStyle: "solid", borderWidth: 1 }}>
              <Text style={{ textAlign: "center", fontFamily: 'ms-black', fontWeight: 'light', fontSize: 10, marginTop: 10 }}>批准人</Text>
              <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 5 }}>Authority</Text>
            </View>

            <View style={{ width: "78%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0 }}>
            </View>
          </View>
      </View>
    )
  }

  return (
    <Document>
      <Page
        style={pageStyle}
        size="A4"
        orientation="portrait">
      
          {createReportHeader()}
          {createReportTittle()}
          {createReportPagination()}
          {createDescripTable()}
          {createTestResultTable()}
          {createReportFooter()}
          
      </Page>
    </Document>
  );
};
  
export default CnasChnReportTemplate;