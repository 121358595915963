

const DashboardHeaderNav = () => {

    return (
        <nav className="py-2 flex space-x-8">
            <a href="https://caiqtest.co.nz/" target="_blank" rel="noreferrer" className="text-gray-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium">
                Home
            </a>

            <a href="https://caiqtest.co.nz/accreditations/" target="_blank" rel="noreferrer" className="text-gray-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium">
                Accreditations
            </a>

            <a href="https://caiqtest.co.nz/test-library/" target="_blank" rel="noreferrer" className="text-gray-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium">
                Test Library
            </a>

            <a href="https://caiqtest.co.nz/request-forms/" target="_blank" rel="noreferrer" className="text-gray-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium">
                Request Form
            </a>

            <a href="https://caiqtest.co.nz/contact-us/" target="_blank" rel="noreferrer" className="text-gray-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium">
                Contact
            </a>
        </nav>
    );
}

export default DashboardHeaderNav;