import React from 'react';
import LoginFrom from '../components/auth/LoginForm';
import HomeTermsSection from '../components/home/HomeTermsSection';
import HomeMainNav from '../components/home/HomeMainNav';
import HomeInfo from '../components/home/HomeInfo';
import HomeFooter from '../components/home/HomeFooter';

const Home = () => {
    // Home or login page
    return (
        <div className="bg-gray-800 min-h-screen">
            <div className="mx-auto max-w-7xl pt-12 pb-5">
                <HomeMainNav />
                <main className="mt-24">
                    <div className="grid grid-cols-12 gap-8">
                        <HomeInfo />
                        <div className="mt-0 col-span-6">
                            <div className="bg-white max-w-md w-full mx-auto rounded-lg overflow-hidden">
                                <LoginFrom />
                                <HomeTermsSection />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <HomeFooter />
        </div>
    );
}

export default Home;
