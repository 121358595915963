import { createContext, useContext, useReducer } from 'react';
import { AuthReducer, initialState } from './AuthReducer';

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

export function useAuthState() {
    const context = useContext(AuthStateContext);
    return context;
}

export function useAuthDispatch() {
    const context = useContext(AuthDispatchContext);
    return context;
}

export const AuthProvider = ({ children }) => {
    const [user, dispatch] = useReducer(AuthReducer, initialState);

    return (
        <AuthStateContext.Provider value = { user } >
            <AuthDispatchContext.Provider value = { dispatch }>
                { children }
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    )
}

export default AuthProvider;
