
const EmailFormatValidator = (email) => {
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailRegex.test(String(email).toLowerCase());
}

export const IntegerValidator = (number) => {
    const integerRegex = /^[0-9\b]+$/;
    return integerRegex.test(number);
}

export default EmailFormatValidator;