import { Page, Text, View, Image, Document } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';

const IanzReportTemplate = ({data}) => {

    const reFormatedData = data.reportData.reduce((acc, val, index) => (index === 0 ? acc.push([val]) : acc[acc.length-1].some(obj => obj.SampNo === val.SampNo) ? acc[acc.length-1].push(val) : acc.push([val])) && acc, []);

    Font.register({
    //https://fonts.google.com/specimen/Open+Sans#standard-styles
    // thin	Equals to value 100
    // ultralight	Equals to value 200
    // light	Equals to value 300
    // normal	Equals to value 400 Default
    // medium	Equals to value 500
    // semibold	Equals to value 600
    // bold	Equals to value 700
    // ultrabold	Equals to value 800
    // heavy	Equals to value 900
    // number	Any integer value between 0 and 1000
    family: 'open-sans',
    fonts: [
        {
            src: '/fonts/open-sans/OpenSans-Light.ttf',
            fontWeight: 'light'
        },
        {
            src: '/fonts/open-sans/OpenSans-Regular.ttf',
            fontWeight: 'normal'
        },
        {
            src: '/fonts/open-sans/OpenSans-SemiBold.ttf',
            fontWeight: 'semibold'
        },
        {
            src: '/fonts/open-sans/OpenSans-Bold.ttf',
            fontWeight: 'bold'
        }
        ]
    })

    const pageStyle = {
        paddingTop: 16,
        paddingHorizontal: 20,
        paddingBottom: 80
    };

    const createReportHeader = () => {
        return (
            <View style={{ flexDirection: "row" }} fixed>
                <View style={{ width: "74%", left: 5 }}>
                    <Image src="/caiq-ianz.jpg"/>
                </View>

                <View style={{ width: "25%" }}>
                    <View style={{ paddingTop: 20, float: 'right', textAlign: 'right', lineHeight: 1.2 }}>
                        <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9 }}>CAIQTEST Pacific Ltd.</Text>
                        <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9 }}>Ruakura Research Centre</Text>
                        <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9 }}>10 Bisley Road</Text>
                        <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9 }}>Private Bag 3123, Hamilton 3240</Text>
                        <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9, textDecoration: 'underline',color: '#0000EE' }}><a href="mailto:sample.receipt@caiqtest.co.nz">sample.receipt@caiqtest.co.nz</a></Text>
                    </View>
                </View>
            </View>
        );
    };

    const createReportTittle = () => {
        return (
            <View style={{ textAlign: 'left', margin: '10px 0', borderStyle: "solid", borderWidth: 3, borderLeftWidth: 0, borderRightWidth: 0, borderColor: '#b8cce4' }} fixed>
                <Text style={{ fontFamily: 'open-sans', fontWeight: 'bold', fontSize: 11, margin: '5px 0', left: 5 }}>Laboratory Test Report</Text>
            </View>
        );
    };

    const createDescripTable = () => {
        return (
            <View style={{ display: "table", width: "auto" }}  fixed>
                <View style={{ flexDirection: "row"}}>
                    <View style={{ width: "50%" }}>
                        <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}>{data.reportData[0].ClientName}</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "right", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Laboratory Reference:</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportInfo.caiqId}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row"}}>
                    <View style={{ width: "50%" }}>
                        <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}> </Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "right", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Version No.:</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportData[0].URegRef}-[R00]</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row"}}>
                    <View style={{ width: "50%" }}>
                        <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}> </Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "right", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Submitted by:</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportInfo.submitter}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row"}}>
                    <View style={{ width: "50%" }}>
                        <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}>{data.reportData[0].ClientAddress1}{data.reportData[0].ClientAddress1 && data.reportData[0].ClientSuburb ? ', ' : ''}{data.reportData[0].ClientSuburb}{data.reportData[0].ClientAddress1 && data.reportData[0].ClientSuburb && data.reportData[0].ClientCity ? ', ' : ''}{data.reportData[0].ClientCity}</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "right", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Date Received:</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportInfo.recDate}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row"}}>
                    <View style={{ width: "50%" }}>
                        <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}> </Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "right", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Date Testing Initiated:</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportInfo.initTestDate}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row"}}>
                    <View style={{ width: "50%" }}>
                        <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}> </Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "right", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Date Completed:</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportData[0].emailedDate}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row"}}>
                    <View style={{ width: "50%" }}>
                        <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}> </Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "right", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Sample Type:</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportInfo.prodCategory}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row"}}>
                    <View style={{ width: "50%" }}>
                        <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}> </Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "right", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Batch No.:</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportInfo.clientBatch}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row"}}>
                    <View style={{ width: "50%" }}>
                        <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}> </Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "right", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Sub-contracted Report #&sup1;:</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportInfo.subContracted}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row"}}>
                    <View style={{ flexDirection: "row", width: "50%"}}>
                        <View style={{ width: "20%" }}>
                            <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}>Attention:</Text>
                        </View>
                        <View style={{ width: "80%" }}>
                            <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}>{data.reportInfo.submitter}</Text>
                        </View>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "right", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Order No.:</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportInfo.order}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row"}}>
                    <View style={{ flexDirection: "row", width: "50%"}}>
                        <View style={{ width: "20%" }}>
                            <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}>Phone:</Text>
                        </View>
                        <View style={{ width: "80%" }}>
                            <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}>{data.reportData[0].ClientTel}</Text>
                        </View>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "right", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Reference:</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportInfo.clientRef}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row"}}>
                    <View style={{ flexDirection: "row", width: "50%"}}>
                        <View style={{ width: "20%" }}>
                            <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}>Email:</Text>
                        </View>
                        <View style={{ width: "80%" }}>
                            <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}>{data.reportData[0].ClientEMail}</Text>
                        </View>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "right", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Report Status:</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>Interim</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row", marginTop: 10}}>
                    <View style={{ width: "100%" }}>
                        <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, left: 5 }}>Result Summary</Text>
                        <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 9, left: 5 }}>Unless specified otherwise, all samples arrived in acceptable condition.</Text>
                        <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 9, left: 5 }}>Results are reported on an ‘as received’ basis and pertain only to the samples submitted. Dates of testing are available on request.</Text>
                    </View>
                </View>
            </View>
        );
    };

    const createTestResultTable =() => {
        return (
            reFormatedData.map((dataArray, i) => (
                dataArray.reduce((acc, val, index) => (index % 3 === 0 ? acc.push([val]) : acc[acc.length-1].push(val)) && acc, []).map((resultArray, j) => (
                    <View key={j} style={{ display: "table", width: "auto", marginTop: 15, marginBottom: 5 }} wrap={false}>
                        <View style={{ flexDirection: "row"}}>
                            <View style={{ width: "20.5%", borderStyle: "solid", borderWidth: 1, backgroundColor: '#005cad' }}>
                                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, margin: '5 0', color: "white" }}>Laboratory ID</Text>
                            </View>

                            <View style={{ width: "19.5%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, backgroundColor: '#005cad' }}>
                                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, margin: '5 0', color: "white" }}>Sample ID</Text>
                            </View>
                            {resultArray.map((result, q) => (
                                <View key={q} style={{ width: "20%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, backgroundColor: '#005cad' }}>
                                    <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, margin: '5 0', color: "white" }}>{result.RptLabel}</Text>
                                </View>
                            ))}
                        </View>

                        <View style={{ flexDirection: "row" }}>
                            <View style={{ width: "20.5%", borderStyle: "solid", borderWidth: 1, borderTopWidth: 0, backgroundColor: "#b8cce4" }}>
                                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, marginBottom: 1 }}></Text>
                            </View>

                            <View style={{ width: "19.5%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, backgroundColor: "#b8cce4" }}>
                                <Text style={{ textAlign: "right", right: 5, fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, marginBottom: 1 }}>Units</Text>
                                <Text style={{ textAlign: "right", right: 5, fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, marginBottom: 1 }}>Reporting Limit</Text>
                                <Text style={{ textAlign: "right", right: 5, fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, marginBottom: 1 }}>Method</Text>
                            </View>
                            {resultArray.map((result, k) => (
                                <View key={k} style={{ width: "20%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, backgroundColor: "#b8cce4" }}>
                                    <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, marginBottom: 1 }}>{result.Units ? result.Units : <>&nbsp;</>}</Text>
                                    <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10, marginBottom: 1 }}>{result.LMin ? result.LMin : <>&nbsp;</>}</Text>
                                    <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 8, marginBottom: 1 }}>{result.MDescrip ? result.MDescrip : <>&nbsp;</>}</Text>
                                </View>
                            ))}
                        </View>

                        <View style={{ flexDirection: "row"}}>
                            <View style={{ width: "20.5%", borderStyle: "solid", borderWidth: 1, borderTopWidth: 0 }}>
                                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>{dataArray[j].URegRef + '-' + dataArray[j].SampNo}</Text>
                            </View>
                            <View style={{ width: "19.5%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>{dataArray[j].SmKFld}</Text>
                            </View>
                            {resultArray.map((result, l) => (
                                <View key={l} style={{ width: "20%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                                    <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, marginBottom: 1 }}>{result.TestResult}</Text>
                                </View>
                            ))}
                        </View>
                    </View>
                ))
            ))
        )
    };

    const createSignatureTable =() => {
        return (
            <View style={{ display: "table", width: "auto", position: "absolute", bottom: 75, left: 20 }} fixed>
                <View style={{ marginTop: 5}}>
                    <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10 }}>CAIQTEST Pacific</Text>
                </View>
            </View>
        )
    };

    const createReportFooter =() => {
        return (
            <>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 8, position: 'absolute', bottom: 60, left: 20 }} render={({ pageNumber, totalPages }) => (
                    `Page ${pageNumber} of ${totalPages}`
                )} fixed/>
                <View style={{ display: "table", width: "auto", position: "absolute", bottom: 20, left: 20 }} fixed>
                    <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 7 }}>'*'sub-contracted test as per agreement with customer (sub-contracted to Analytica Laboratories, IANZ accredited, MPI RLP)</Text>
                    <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 7 }}>'**'This test is not in accreditation scope of CAIQTEST (Pacific)</Text>
                    <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 7 }}>This test report shall not be reproduced except in full, without the written permission of CAIQTEST Pacific</Text>
                    <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 7 }}>1 – Individuals/Individual who authorised the subcontracted results on this report are available on request</Text>
                </View>
            </>
        )
    };

    

    return (
        <Document>
            <Page
                style={pageStyle}
                size="A4"
                orientation="portrait">
                {createReportHeader()}
                {createReportTittle()}
                {createDescripTable()}
                {createTestResultTable()}
                {createSignatureTable()}
                {createReportFooter()}
            </Page>
        </Document>
    );
};
  
export default IanzReportTemplate;