import Axios from 'axios'

let urls = {
    test: `http://localhost:8001`,
    development: 'http://localhost:8000/',
    production: ''
}

//const token = localStorage.getItem("access-key");

const api = Axios.create({
    baseURL: urls[process.env.NODE_ENV],
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        //'Authorization': token ? `Bearer ${token}` : null
        Authorization: {  // A hacky way to get the most recent token while Axios.create 
            toString () {
                return `Bearer ${localStorage.getItem('access-key')}`
            }
        }
    },
    timeout: 60000
});

export default api;