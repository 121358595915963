const HomeMainNav = () => {
    return (
        <nav className="relative max-w-7xl mx-auto flex items-center justify-between px-6">
            <div className="flex items-center flex-1 pl-2">
                <div className="flex items-center justify-between w-auto h-12">
                    <img className="h-full w-auto" src="/report-logo-no-bg-invert.png" alt="CAIQTest Logo"/> 
                </div>

                <div className="flex ml-10 space-x-10">
                    <a href="https://caiqtest.co.nz/" className="font-medium text-white hover:text-gray-300" target="_blank" rel="noreferrer">Home</a>

                    <a href="https://caiqtest.co.nz/accreditations/" className="font-medium text-white hover:text-gray-300" target="_blank" rel="noreferrer">Accreditations</a>

                    <a href="https://caiqtest.co.nz/test-library/" className="font-medium text-white hover:text-gray-300" target="_blank" rel="noreferrer">Test Library</a>

                    <a href="https://caiqtest.co.nz/contact-us/" className="font-medium text-white hover:text-gray-300" target="_blank" rel="noreferrer">Contact</a>
                </div>
            </div>
        </nav>
    );
}

export default HomeMainNav;