import { Page, Text, View, Image, Document } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import { IsoDateToNzDateWithMonthNameStr } from '../../helper/DateHelper';

const CnasReportTemplate = ({data}) => {

  Font.register({
    //https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/
    //https://fonts.google.com/specimen/Open+Sans#standard-styles
    // thin	Equals to value 100
    // ultralight	Equals to value 200
    // light	Equals to value 300
    // normal	Equals to value 400 Default
    // medium	Equals to value 500
    // semibold	Equals to value 600
    // bold	Equals to value 700
    // ultrabold	Equals to value 800
    // heavy	Equals to value 900
    // number	Any integer value between 0 and 1000
    family: 'open-sans',
    fonts: [
      {
        src: '/fonts/open-sans/OpenSans-Light.ttf',
        fontWeight: 'light'
      },
      {
        src: '/fonts/open-sans/OpenSans-Regular.ttf',
        fontWeight: 'normal'
      },
      {
        src: '/fonts/open-sans/OpenSans-SemiBold.ttf',
        fontWeight: 'semibold'
      },
      {
        src: '/fonts/open-sans/OpenSans-Bold.ttf',
        fontWeight: 'bold'
      }
      // {
      //   src: '/fonts/open-sans/OpenSans-BoldItalic.ttf',
      //   fontWeight: 'bold',
      //   fontStyle: 'italic'
      // },
      // {
      //   src: '/fonts/open-sans/OpenSans-ExtraBoldItalic.ttf',
      //   fontWeight: 'ultrabold',
      //   fontStyle: 'italic'
      // }
    ]
  })

  const pageStyle = {
      paddingTop: 16,
      paddingHorizontal: 40,
      paddingBottom: 56
  };

  const createReportHeader = () => {
    return (
      <View style={{ flexDirection: "row" }} fixed>
        <View style={{ width: "50%" }}>
          <Image src="/report-logo.png"/>
        </View>

        <View style={{ width: "50%" }}>
          <View style={{ paddingTop: 20, float: 'right', textAlign: 'right', lineHeight: 1.2 }}>
            <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9 }}>CAIQTEST Pacific Ltd.</Text>
            <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9 }}>Ruakura Research Centre</Text>
            <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9 }}>10 Bisley Road</Text>
            <Text style={{ fontFamily: 'open-sans', fontWeight: 'light', fontSize: 9 }}>Private Bag 3123, Hamilton 3240</Text>
          </View>
        </View>
      </View>
    );
  };

  const createReportTittle = () => {
    return (
      <View style={{ textAlign: 'center', margin: '20px 0' }} fixed>
        <Text style={{ fontFamily: 'open-sans', fontWeight: 'bold', fontSize: 15 }}>Test Report</Text>
      </View>
    );
  };

  const createReportPagination = () => {
    return (
      <>
        <View style={{ flexDirection: "row", marginBottom: 2 }} fixed>
          <View  style={{ width: "22%" }}>
            <Text style={{ paddingLeft: 5, fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10 }}>Report Number:</Text>
          </View>
          <View  style={{ width: "35%" }}>
            <Text style={{ textAlign: "left",paddingLeft: 3, fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 10 }}>{data.reportInfo.caiqId}</Text>
          </View>
        </View>

        <Text style={{ fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 9, position: 'absolute', right: 45, top: 156.5 }} render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )} fixed/>
      </>
    );
  };

  const createDescripTable = () => {
    return (
      <View style={{ display: "table", width: "auto"}}  fixed>
            <View style={{ flexDirection: "row"}}>
              <View style={{ width: "20%", borderStyle: "solid", borderWidth: 1 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>Client</Text>
              </View>

              <View style={{ width: "80%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'semibold', fontSize: 11, margin: 5 }}>{data.reportData[0].ClientName}</Text>
              </View>
            </View>

            <View style={{ flexDirection: "row"}}>
              <View style={{ width: "20%", borderStyle: "solid", borderWidth: 1, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>Sample Name</Text>
              </View>

              <View style={{ width: "40%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '5 0' }}>{data.reportData[0].JobDescrip}</Text>
              </View>
              
              <View style={{ width: "22%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>Test Classification</Text>
              </View>

              <View style={{ width: "18%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>Commitment</Text>
              </View>
            </View>

            <View style={{ flexDirection: "row"}}>
              <View style={{ width: "20%", borderStyle: "solid", borderWidth: 1, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>Sample ID</Text>
              </View>

              <View style={{ width: "40%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '5 0' }}>{data.reportInfo.clientBatch}</Text>
              </View>
              
              <View style={{ width: "22%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>Sample Reference No.</Text>
              </View>

              <View style={{ width: "18%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>{data.reportData[0].URegRef}</Text>
              </View>
            </View>

            <View style={{ flexDirection: "row"}}>
              <View style={{ width: "20%", borderStyle: "solid", borderWidth: 1, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '10 5' }}>Sample Description</Text>
              </View>

              <View style={{ width: "40%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '5 0' }}>{data.reportData[0].JobDescrip}{data.reportInfo.weightUnit && ', '}{data.reportInfo.weightUnit}{data.reportInfo.prodDate && ', '}{data.reportInfo.prodDate}{data.reportInfo.prodDate && ' - '}{data.reportInfo.expiryDate}</Text>
              </View>
              
              <View style={{ width: "22%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '10 5' }}>Issued Date</Text>
              </View>

              <View style={{ width: "18%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '10 0' }}>{IsoDateToNzDateWithMonthNameStr(new Date())}</Text>
              </View>
            </View>

            <View style={{ flexDirection: "row"}}>
              <View style={{ width: "100%", borderStyle: "solid", borderWidth: 1, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '0 5' }}>Clarification:</Text>
                <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '0 5' }}>1. The sample’s name and ID are provided by the client. CAIQTEST is not responsible for the accuracy of the &nbsp;&nbsp;&nbsp;&nbsp;provided information.</Text>
                <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '0 5' }}>2. All tests have been taken according to the client’s request. Results are reported on an ‘’as received” basis &nbsp;&nbsp;&nbsp;&nbsp;and pertain only to the samples submitted. Dates of testing are available on request.</Text>
                <Text style={{ textAlign: "left", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '0 5' }}>3. “ * ” sub-contracted test as per agreement with customer (sub-contracted to Analytica Laboratories, IANZ &nbsp;&nbsp;&nbsp;&nbsp;accredited, MPI RLP)</Text>
              </View>
            </View>
          </View>
    );
  };

  const createTestResultTable =() => {
    return (
      <>
        <View style={{ display: "table", width: "auto", marginTop: 10 }} fixed>
          <View style={{ flexDirection: "row"}}>
            <View style={{ width: "37%", borderStyle: "solid", borderWidth: 1 }}>
              <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>Test Item</Text>
            </View>

            <View style={{ width: "29%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0 }}>
              <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>Method</Text>
            </View>
            
            <View style={{ width: "14%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0 }}>
              <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>Unit</Text>
            </View>

            <View style={{ width: "8%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0 }}>
              <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>LOQ</Text>
            </View>

            <View style={{ width: "12%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0 }}>
              <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>Result</Text>
            </View>
          </View>
        </View>

        <View style={{ display: "table", width: "auto" }}>
          {data.reportData.map((result, i) => (
            <View key={i} style={{ flexDirection: "row"}}  wrap={false}>
              <View style={{ width: "37%", borderStyle: "solid", borderWidth: 1, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>{result.RptLabel}</Text>
              </View>

              <View style={{ width: "29%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>{result.MDescrip}</Text>
              </View>
              
              <View style={{ width: "14%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>{result.Units}</Text>
              </View>

              <View style={{ width: "8%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 5 }}>{result.LMin}</Text>
              </View>
              
              <View style={{ width: "12%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }}>
                <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: '5 3' }}>{result.TestResult}</Text>
              </View>
            </View>
          ))}
        </View>
      </>
    )
  }

  const createReportFooter =() => {
    return (
      <View style={{ display: "table", width: "auto", position: "absolute", left: 40, bottom: 25, height:45 }} fixed>
          <View style={{ flexDirection: "row"}}>
            <View style={{ width: "20%", borderStyle: "solid", borderWidth: 1 }}>
              <Text style={{ textAlign: "center", fontFamily: 'open-sans', fontWeight: 'normal', fontSize: 10, margin: 13 }}>Authority</Text>
            </View>

            <View style={{ width: "80%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0 }}>
            </View>
          </View>
      </View>
    )
  }

  return (
    <Document>
      <Page
        style={pageStyle}
        size="A4"
        orientation="portrait">
        {createReportHeader()}
        {createReportTittle()}
        {createReportPagination()}
        {createDescripTable()}
        {createTestResultTable()}
        {createReportFooter()}
      </Page>
    </Document>
  );
};
  
export default CnasReportTemplate;